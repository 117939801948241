import { useEffect } from 'react'
import { ModalIconSM, ModalTitleBar, WhiteClose, ModalButton } from '../../../Modal/ModalStyles';
import {
	CaCAD, CaCBirthdate, CaCBody, CaCBox, CaCContainter, CaCContent, CaCImage, CaCName, CaCLink,
	CaCRoles, CaCSectionTitle, CaCSection, CaCSectionItem, CaCMiddleDot, Role, CaCSections, CaCLinks
} from './CastAndCrewStyle'
import Close from '../../../../img/Icons/White Icons/TitanTV Icon_Close_White.png'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { DataState, ModalState } from '../../../../interfaces/enums';
import { selectCastDataStatus, selectCastData } from '../../detailsSlice';
import GridFailed from '../GridFailed';
import { useNavigate, useLocation } from 'react-router-dom';
import { setModalContent, setModalOpen } from '../../../Modal/modalSlice';
import { FreestarAd } from '../../../Freestar/FreestarAd';
import GridLoading from '../GridLoading';
import Back from '../../../../img/Icons/White Icons/TitanTV Icon_Back_White.png';
import GA4 from 'react-ga4'

const CastAndCrew = () => {
	const cast = useAppSelector(selectCastData);
	const loading = useAppSelector(selectCastDataStatus);
	const dispatch = useAppDispatch();
	const bioDetails = useAppSelector(selectCastData);
	let contributions: string[] = [];
	const navigate = useNavigate();
	const path = useLocation().pathname;

	contributions = [];

	const contributionArrayBuilder = () => {
		let isVoiceActor = false;
		cast.contributions.forEach((con, i) => {
			let isThere = contributions.some((conString) => con.type === conString)
			if (!isThere && !isVoiceActor) {
				if (con.type === 'Voice Of') {
					isVoiceActor = true;
					contributions.push('Voice Actor');
				} else {
					contributions.push(con.type);
				}
			}
		});
	}

	const acclaim = 'acclaim', trade = 'trade', trivia = 'trivia', origins = 'origins', quotes = 'quotes'
	let acclaimArray: string[] = [], tradeArray: string[] = [], triviaArray: string[] = [], originsArray: string[] = [], quotesArray: string[] = []
	const factSorter = () => {
		let facts = cast.textItems
		if (loading === DataState.SUCCEEDED) {
			for (let i = 0; i < facts.length; i++) {
				switch (facts[i].type) {
					case acclaim:
						acclaimArray.push(facts[i].value);
						break;
					case trade:
						tradeArray.push(facts[i].value);
						break;
					case trivia:
						triviaArray.push(facts[i].value);
						break;
					case origins:
						originsArray.push(facts[i].value);
						break;
					case quotes:
						quotesArray.push(facts[i].value);
						break;
					default:
						break;
				}
			}
		}
	}

	let closeCastCrew = (e: any) => {
		e.preventDefault();
		navigate(path);
		handleClose();
	}

	useEffect(() => {
		//GA4.send({ hitType: "pageview", page: '/contributors' });

		document.body.style.overflow = 'hidden';
		return () => {
			document.body.style.overflow = 'visible';
		}
	}, []);

	const handleClose = () => {
		dispatch(setModalOpen())
		document.body.style.overflow = 'visible';
	}

	if (loading === DataState.SUCCEEDED) {
		contributionArrayBuilder();
		factSorter();
	}

	return (
		<>
			{loading === DataState.FAILED ? <GridFailed errorCode={cast.errorCode} errorMessage={cast.errorMessage} /> :
				<CaCBox onClick={(e) => e.stopPropagation()}>
					<ModalTitleBar>
						<ModalButton onClick={() => dispatch(setModalContent(ModalState.EVENTDETAILS))}>
							<ModalIconSM src={Back} alt='Back' /> Back
						</ModalButton>
						<WhiteClose onClick={handleClose}>
							Close <ModalIconSM src={Close} alt='Close' />
						</WhiteClose>
					</ModalTitleBar>
					<CaCContainter>
						{(loading === DataState.LOADING) ? <GridLoading /> :
							<CaCBody>
								{(bioDetails === undefined || bioDetails.images.length === 0) ? '' :
									<div>
										<CaCImage src={bioDetails.images[0].url} onError={(event) => event.currentTarget.style.display = 'none'} />
									</div>}
								<CaCContent>
									{/* <CaCLine></CaCLine> */}
									<CaCName>
										{cast.firstName} {cast.lastName}
										<CaCBirthdate>
											&nbsp;{(cast.dateOfBirth.length === 0) ? '' : (cast.dateOfBirth)}
										</CaCBirthdate>
									</CaCName>
									<CaCRoles>
										{contributions.map((con, i, arr) => {
											return (
												<Role key={'con: ' + i}>
													{con} {(i + 1) === arr.length ?
														<CaCMiddleDot></CaCMiddleDot> :
														<CaCMiddleDot> · </CaCMiddleDot>
													}
												</Role>
											)
										})}
									</CaCRoles>
									<br />
									<CaCSections>
										{originsArray.length === 0 ? '' :
											<>
												<CaCSectionTitle>Origns</CaCSectionTitle>
												<CaCSection>
													<CaCSectionItem>{cast.firstName} was born {cast.dateOfBirth} in {cast.birthPlace}.</CaCSectionItem>
													{originsArray.map((item, i) => {
														return (<CaCSectionItem key={'origins: ' + i}>
															{item.toString()}
														</CaCSectionItem>)
													})}
												</CaCSection>
												<br />
											</>
										}

										{tradeArray.length === 0 ? '' :
											<>
												<CaCSectionTitle>Trade</CaCSectionTitle>
												<CaCSection>
													{tradeArray.map((item, i) => {
														return (<CaCSectionItem key={'trade: ' + i}>
															{item}
														</CaCSectionItem>)
													})}
												</CaCSection>
												<br />
											</>
										}

										{acclaimArray.length === 0 ? '' :
											<>
												<CaCSectionTitle>Acclaim</CaCSectionTitle>
												<CaCSection>
													{acclaimArray.map((item, i) => {
														return (<CaCSectionItem key={'acclaim: ' + i}>
															{item}
														</CaCSectionItem>)
													})}
												</CaCSection>
												<br />
											</>
										}

										{triviaArray.length === 0 ? '' :
											<>
												<CaCSectionTitle>Trivia</CaCSectionTitle>
												<CaCSection>
													{triviaArray.map((item, i) => {
														return (<CaCSectionItem key={'trivia: ' + i}>
															{item}
														</CaCSectionItem>)
													})}
												</CaCSection>
												<br />
											</>
										}

										{quotesArray.length === 0 ? '' :
											<>
												<CaCSectionTitle>Quote</CaCSectionTitle>
												<CaCSection>
													{quotesArray.map((item, i) => {
														return (<CaCSectionItem key={'quotes: ' + i}>
															{item}
														</CaCSectionItem>)
													})}
												</CaCSection>
											</>
										}
									</CaCSections>
									<CaCLinks>
										{cast.relatedMaterial.map((rm) => {
											return (
												<CaCLink title={`Click to got to ${cast.firstName}'s ${rm.origin} page`} href={rm.value} target="_blank" rel="noopener noreferrer">
													{cast.firstName}'s {rm.origin}
												</CaCLink>
											)
										})}
									</CaCLinks>
								</CaCContent>
							</CaCBody>
						}
						<CaCAD>
							<FreestarAd placementName={'titantv_show_description'}></FreestarAd>
						</CaCAD>
					</CaCContainter>
				</CaCBox>
			}
		</>
	)
}

export default CastAndCrew