import { useState, useEffect } from 'react';
import { Event, ScheduleChannel } from '../../interfaces/schedule'
import { Channel } from '../../interfaces/channels';
import { Favorites, UserLineup } from '../../interfaces/settings';
import { ScreenSize } from '../../interfaces/enums';

//#region baseUrl
let baseUrl = 'api/';
//let baseUrl = 'http://qa3.titantv.com/api/'
//let baseUrl = 'http://dev2-max-api.titantv.com/api/';
//let baseUrl = 'https://beta.titantv.com/api/'
const defaultUserId = 'ffffffff-ffff-ffff-ffff-ffffffffffff';

export const getBaseUrl = () => {
	return baseUrl;
}

export const setBaseUrl = (url: string) => {
	baseUrl = url;
}
//#endregion

//#region max lineups
let maxLineups = 8;

export const getMaxLineups = (): number => {
	return maxLineups;
}

export const setMaxLineups = (newMaxLineups: number) => {
	maxLineups = newMaxLineups;
}
//#endregion

/* 
	largeScreen: '1120px',
	medScreen: '980px',
	smallScreen: '750px',
	mobile: '645px',
*/

export const getScreenSizeType = () => {
	const { width } = getWindowDimensions()

	if (width > 1215) {
		return ScreenSize.LARGE
	} else if (width > 1080) {
		return ScreenSize.MEDIUM
	} else if (width > 645) {
		return ScreenSize.SMALL
	} else {
		return ScreenSize.MOBILE
	}
}

export const eventFlyOverText = (eventTitle: string, epsTitle: string, shouldDisplay: boolean): string => {
	if (!shouldDisplay) { return '' }
	return eventTitle + '\nClick to view details'
	if (epsTitle === '') {
		return eventTitle + '\nClick to view details'
	} else {
		return eventTitle + '\n' + epsTitle + '\nClick to view details'
	}
}

export const leadingZeroBuilder = (num: number): string => {
	let numString = '';
	if (num < 10) {
		numString = "0" + num;
	} else {
		numString = num.toString();
	}

	return numString
}

/*
	window width/height from stackoverflow answer
	https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
*/

export function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height
	};
}

/**
 * 
 * @returns window height and width as an object in state so it updates automaticly
 */
export default function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowDimensions;
}

/**
 * Used to adjest font size based on the settings for the grid
 * @param fontModifer {number} Gotten from gridSettings Slice
 * @param baseFontSize {number} default is 13 if not given
 * @returns Font Size after calculating change
 */
export const fontSizeCalculation = (fontModifer: number, baseFontSize: number = 13): number => {
	return baseFontSize * fontModifer;
}

export const TooManyLineups = (lineupsQuanity: number, isAnon: boolean): boolean => {
	const maxNumberOfLineupsMinusOne = maxLineups - 1;
	if (isAnon && lineupsQuanity > maxNumberOfLineupsMinusOne) {
		return true;
	} else {
		return false;
	}
}

// https://codingstatus.com/password-and-confirm-password-validation-in-react-js/
export const passwordValidation = (pass: string): boolean => {
	let valid = true

	const uppercaseRegExp = /(?=.*?[A-Z])/;
	const lowercaseRegExp = /(?=.*?[a-z])/;
	const digitsRegExp = /(?=.*?[0-9])/;
	const minLengthRegExp = /.{8,}/;

	const passwordLength = pass.length;
	const uppercasePassword = uppercaseRegExp.test(pass);
	const lowercasePassword = lowercaseRegExp.test(pass);
	const digitsPassword = digitsRegExp.test(pass);
	const minLengthPassword = minLengthRegExp.test(pass);

	if (passwordLength === 0 || pass === '') { valid = false; }
	if (!uppercasePassword) { valid = false; }
	if (!lowercasePassword) { valid = false; }
	if (!digitsPassword) { valid = false; }
	if (!minLengthPassword) { valid = false; }

	return valid;
}

/*
	function taken from
	https://www.arungudelli.com/tutorial/javascript/how-to-create-uuid-guid-in-javascript-with-examples/
*/
export const CreateUUID = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
}

export const GetDefaultUser = () => {
	return defaultUserId;
}

export const logout = () => {
	localStorage.removeItem('userId');
	sessionStorage.removeItem('userId');
	localStorage.removeItem('ccpaMessage');
	window.location.reload();
}

export const checkEmailValid = (email: string) => {
	const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return res.test(String(email).toLowerCase());
}


export interface TVPIData {
	event: Event;
	channel: Channel;
	lineup: UserLineup;
	burn: boolean;
	watch: boolean;
	record: boolean;
}

/* 
	0	Default NULL
	1	Analog Broadcast
	2	Digital Broadcast
	3	Analog Cable
	4	Digital Cable
	5	Digital Cable w/QAM
	6	Satellite
	7	Over the Top
*/

const getChannelSource = (channel: Channel): string => {
	let type = 'unknown'
	switch (channel.providerTypeId) {
		case 1:
			type = 'analog'
			break;
		case 2:
			type = 'digital'
			break;
		case 3:
			type = 'cable'
			break;
		case 4:
		case 5:
			type = 'digital_cable'
			break;
		case 6:
			type = 'satellite'
			break;
		default:
			if (channel.majorChannel > 0) {
				type = 'digital'
			} else {
				type = 'digital_cable'
			}
			break;
	}
	return type;
}

/**
 * 
 * @param data type TVPIData
 * @returns 
 */
export const tvpiBuilder = (data: TVPIData): Blob => {
	let { event, channel, burn, watch } = data;
	let start = new Date(event.startTime);
	let end = new Date(event.endTime);
	const durationHours = Math.floor((event.duration / 60) / 60);
	const durationMin = (event.duration / 60) - (durationHours * 60);

	let startDate = `${start.getUTCFullYear()}${leadingZeroBuilder(start.getUTCMonth() + 1)}${leadingZeroBuilder(start.getUTCDate())}`
	let startTime = `${leadingZeroBuilder(start.getUTCHours())}:${leadingZeroBuilder(start.getUTCMinutes())}`
	let endDate = `${end.getUTCFullYear()}${leadingZeroBuilder(end.getUTCMonth() + 1)}${leadingZeroBuilder(end.getUTCDate())}`
	let endTime = `${leadingZeroBuilder(end.getUTCHours())}:${leadingZeroBuilder(end.getUTCMinutes())}`
	let durationString = `${leadingZeroBuilder(durationHours)}:${leadingZeroBuilder(durationMin)}`
	let instructionType = 'program'
	if (watch) {
		instructionType = 'viewer'
	}

	let blobString = `<tv-${instructionType}-info version="1.0">\n<program>\n<station>${escapeChar(channel.callSign)}</station>\n`
	blobString = blobString + `<tv-mode>${getChannelSource(channel)}</tv-mode>\n<program-title>${escapeChar(event.title)}</program-title>\n`
	if (event.episodeTitle !== '') {
		blobString = blobString + `<episode-title>${escapeChar(event.episodeTitle)}</episode-title>\n`
	}
	blobString = blobString + `<program-description>${escapeChar(event.description)}</program-description>\n`
	blobString = blobString + `<start-date>${startDate}</start-date>\n<start-time>${startTime}</start-time>\n<end-date>${endDate}</end-date>\n`
	blobString = blobString + `<end-time>${endTime}</end-time>\n<duration>${durationString}</duration>\n<rf-channel>${channel.rfChannel}</rf-channel>\n`
	if (channel.majorChannel > 0) {
		blobString = blobString + `<psip-major>${channel.majorChannel}</psip-major>\n<psip-minor>${channel.minorChannel}</psip-minor>\n`
		blobString = blobString + `<stream-number>${channel.minorChannel}</stream-number>\n`
	}
	if (burn) {
		blobString = blobString + `<destination>dvd</destination>\n`
	}
	blobString = blobString + `</program>\n</tv-${instructionType}-info>\n`
	let blob = new Blob([blobString], { type: "text/plain;charset=utf-8" })

	return blob
}

export const icsBuilder = (data: { event: Event, channel: Channel }): Blob => {
	let { event, channel } = data;
	let startTime = new Date(event.startTime);
	let endTime = new Date(event.endTime);
	let now = new Date();
	let startTimeString = `${startTime.getUTCFullYear()}${leadingZeroBuilder(startTime.getUTCMonth() + 1)}${leadingZeroBuilder(startTime.getUTCDate())}${leadingZeroBuilder(startTime.getUTCHours())}${leadingZeroBuilder(startTime.getUTCMinutes())}Z`
	let endTimeString = `${endTime.getUTCFullYear()}${leadingZeroBuilder(endTime.getUTCMonth() + 1)}${leadingZeroBuilder(endTime.getUTCDate())}${leadingZeroBuilder(endTime.getUTCHours())}${leadingZeroBuilder(endTime.getUTCMinutes())}Z`
	let nowTimeString = `${now.getUTCFullYear()}${leadingZeroBuilder(now.getUTCMonth() + 1)}${leadingZeroBuilder(now.getUTCDate())}${leadingZeroBuilder(now.getUTCHours())}${leadingZeroBuilder(now.getUTCMinutes())}Z`
	let channeNumber = '';
	if (channel.majorChannel > 0) {
		channeNumber = `${channel.majorChannel}.${channel.minorChannel}`
	} else {
		channeNumber = channel.rfChannel + ''
	}

	// let eventEps = ''
	// if (event.episodeTitle !== '') {
	// 	eventEps = `"${event.episodeTitle}"`
	// }

	let blobString = 'BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:-//titantv//reminder\nCALSCALE:GREGORIAN\nMETHOD:PUBLISH\nBEGIN:VEVENT\nSEQUENCE:0\n';
	blobString = blobString + `DTSTART:${startTimeString}` + '\n'
	blobString = blobString + `DTEND:${endTimeString}` + '\n'
	blobString = blobString + `SUMMARY:TitanTV Program Reminder: ${event.title}` + '\n'
	blobString = blobString + `DESCRIPTION:${event.title} "${event.episodeTitle}" ${channel.callSign} ${channeNumber}\n`
	blobString = blobString + `UID:${CreateUUID()}` + '\n' + `DTSTAMP:${nowTimeString}`;
	blobString = blobString + '\nEND:VEVENT\nEND:VCALENDAR';

	let blob = new Blob([blobString], { type: "text/plain;charset=utf-8" })
	return blob;
}

// const blobString = `${settingsUrl}
// ${JSON.stringify(arg.settings)}
// `
// dataToFile(blobString, 'saveDataCall.txt');
export const dataToFile = (blobString: string, fileName: string) => {
	let blob = new Blob([blobString], { type: "text/plain;charset=utf-8" })
	const url = URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.download = fileName;
	link.href = url;
	link.click();
}

export interface FilterCritria {
	displayGenre: string;
	displayNewProgram: boolean;
	displayMovies: boolean;
	displayFavorites: boolean;
}

export const filterEventShouldDisplay = (filterCritria: FilterCritria, isFav: boolean, event: Event): boolean => {
	let display = true;

	// if only the genre pulldown is changed
	if (filterCritria.displayGenre !== 'all' && !filterCritria.displayFavorites && !filterCritria.displayMovies && !filterCritria.displayNewProgram) {
		if (event.displayGenre === filterCritria.displayGenre) {
			return true;
		} else {
			return false;
		}
	}

	// only new programs
	if (filterCritria.displayGenre === 'all' && !filterCritria.displayFavorites && !filterCritria.displayMovies && !filterCritria.displayNewProgram) {
		if (event.newRepeat === 'N') {
			return true;
		} else {
			return false;
		}
	}

	if (isFav && filterCritria.displayFavorites) { isFav = true; }

	// only movies
	if (filterCritria.displayGenre === 'all' && !filterCritria.displayFavorites && filterCritria.displayMovies && !filterCritria.displayNewProgram) {
		if (event.programType === 'Movie') {
			return true;
		} else {
			return false;
		}
	}

	// only favorites
	if (filterCritria.displayGenre === 'all' && filterCritria.displayFavorites && !filterCritria.displayMovies && !filterCritria.displayNewProgram) {
		return isFav
	}

	// main filter
	if (filterCritria.displayGenre !== 'all') {
		if (!(event.displayGenre === filterCritria.displayGenre)) {
			display = false;
		}
	}
	if (!filterCritria.displayNewProgram && (event.newRepeat === 'N')) {
		display = false;
	}
	if (filterCritria.displayNewProgram && !(event.newRepeat === 'N')) {
		display = false;
	}
	if (!filterCritria.displayMovies && (event.programType === 'Movie')) {
		display = false;
	}
	if (filterCritria.displayMovies && !(event.programType === 'Movie')) {
		display = false;
	}
	if (!filterCritria.displayFavorites && isFav) {
		display = false;
	}
	if (filterCritria.displayFavorites && !isFav) {
		display = false;
	}

	return display;
}

export const filterChannelShouldDisplay = (filterCritria: FilterCritria, favs: Favorites, scheduleChannel: ScheduleChannel): boolean => {
	let display = true;
	scheduleChannel.days[0].events.forEach((event) => {
		let isFav = false
		for (let i = 0; i < favs.favorites.length; i++) {
			if (favs.favorites[i].title === event.title) {
				isFav = true;
				break;
			}
		}
		if (filterEventShouldDisplay(filterCritria, isFav, event)) {
			return true;
		} else {
			display = false;
		}
	})
	return display;
}

export const getChannelRowHeight = (compactMode: boolean): string => {
	if (compactMode) {
		return '45px'
	} else {
		return '105px'
	}
}

export const handleTimeZoneName = (offset: number) => {
	switch (offset) {
		case -240:
			return 'Atlantic'
		case -300:
			return 'Eastern'
		case -360:
			return 'Central'
		case -420:
			return 'Mountain'
		case -480:
			return 'Pacific'
		case -540:
			return 'Alaska'
		case -600:
			return 'Hawaii'
		case -660:
			return 'Samoa'
		case 600:
			return 'Guam'
		default:
			return 'GMT'
	}
}


export const escapeChar = (string: string): string => {
	let string2 = string;
	string2 = string2.replaceAll("&", "&amp;");
	string2 = string2.replaceAll("<", "&lt;");
	string2 = string2.replaceAll(">", "&gt;");
	string2 = string2.replaceAll("'", "&apos;");
	string2 = string2.replaceAll("\"", "&quot;");

	return string2;
}

export const shouldDisplayNGChannel = (isNG: boolean, hideNG: boolean) => {
	if (isNG) {
		if (hideNG) {
			return false
		}
	}
	return true;
}