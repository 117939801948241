import {
	GridSelectorImg, GridSelectorsContainer, BackButton, GridRadio, GridRadioLabel,
	ChannelGridSelectorContainer, ChannelGridIconContainer, Printer, GridRadioGroup,
	GridPullDown
} from './GridStyles';
import Back from '../../../img/Icons/White Icons/TitanTV Icon_Back_White.png';
import Print from '../../../img/Icons/White Icons/TitanTV Icon_Print_White.png';
import {
	selectLineupChannels, fetchLineupChannels, selectGridLineupID, selectChannelSort, fetchChannelLineup,
	setMainGridScrollID
} from '../mainGridSlice';
import { selectUserId } from '../../UserSettingsPages/SettingsState/userSlice';
import {
	selectAudioDescription, selectCastBio, selectCellDescription, selectDefaultLineup, selectDetailsShowCard,
	selectDisplayChannelLogo, selectDisplayShowCards, selectEpsTitle, selectFixedCellHeight, selectFontSize,
	selectHDIndicator, selectHideNGChannels, selectMovieRating, selectNewRepeat, selectSocialMediaLinks,
	selectNextGenIndicator, selectPostalCode, selectProgramType, selectSeasonNumber, selectTvRating,
	selectDetailsSynopsis, selectGenreDisplay, selectStartTime, selectWrapCellText, selectYearProduction,
	selectDailyGridWidth, setDailyGridWidth, postUserSettings, selectGridColorScheme, selectEnableWatch,
	selectEnableRecord, selectEnableBurnToDVD, selectEnableRemoteScheduling, selectPVRChannel,
	selectCompactMode, selectSettingMainGridDuration
} from '../../UserSettingsPages/SettingsState/gridSettingsSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ChangeEvent, useEffect, useState } from 'react';
import useWindowDimensions, { CreateUUID, leadingZeroBuilder } from '../../Global/GobalFunctions';
import { PostUserSettings } from '../../../interfaces/settings';
import { Channel } from '../../../interfaces/channels';
import { useNavigate, useParams } from 'react-router-dom';

const DailyGridSelector = () => {

	const { width } = useWindowDimensions();
	const dispatch = useAppDispatch();
	const days = useAppSelector(selectDailyGridWidth);
	const channels = useAppSelector(selectLineupChannels);
	const userId = useAppSelector(selectUserId);
	const lineupId = useAppSelector(selectGridLineupID);
	const params = useParams();
	const nav = useNavigate();
	const hideNextGen = useAppSelector(selectHideNGChannels)
	const [channelIndex, setChannelIndex] = useState('')

	if (width <= 820) { dispatch(setDailyGridWidth('1')) }
	if (width <= 1180 && days > '3') { dispatch(setDailyGridWidth('3')) }
	if (width <= 1500 && days > '5') { dispatch(setDailyGridWidth('5')) }

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		dispatch(setDailyGridWidth(e.target.value));
		postSettings.gridSettings.gridDailyWidth = e.target.value
		dispatch(postUserSettings(postSettings));
	}

	const postSettings: PostUserSettings = {
		postalCode: useAppSelector(selectPostalCode),
		defaultLineupId: useAppSelector(selectDefaultLineup),
		userId: useAppSelector(selectUserId),
		gridSettings: {
			gridStartHour: parseInt(useAppSelector(selectStartTime)),
			gridDuration: useAppSelector(selectSettingMainGridDuration),
			channelSortOrder: useAppSelector(selectChannelSort),
			gridFontSize: useAppSelector(selectFontSize),
			hideNextGen: hideNextGen,
			wrapCellText: useAppSelector(selectWrapCellText),
			fixedCellHeight: useAppSelector(selectFixedCellHeight),
			includeLogos: useAppSelector(selectDisplayChannelLogo),
			includeShowCards: useAppSelector(selectDisplayShowCards),
			gridDailyWidth: useAppSelector(selectDailyGridWidth),
			gridColorScheme: useAppSelector(selectGridColorScheme),
			compactMode: useAppSelector(selectCompactMode)
		},
		cellSettings: {
			includeDescription: useAppSelector(selectCellDescription),
			includeEpisodeTitle: useAppSelector(selectEpsTitle),
			includeSeasonNumbers: useAppSelector(selectSeasonNumber),
			includeHDIndicator: useAppSelector(selectHDIndicator),
			includeNewRepeatIndicator: useAppSelector(selectNewRepeat),
			includeGenres: useAppSelector(selectGenreDisplay),
			includeMovieYear: useAppSelector(selectYearProduction),
			includeMovieRatings: useAppSelector(selectMovieRating),
			includeTVRatings: useAppSelector(selectTvRating),
			includeOriginalAirDate: true,
			includeProgramType: useAppSelector(selectProgramType),
			includeNextGenIndicator: useAppSelector(selectNextGenIndicator),
			includeAudioStreams: useAppSelector(selectAudioDescription),
			includeSocialMediaLinks: useAppSelector(selectSocialMediaLinks),
		},
		detailSettings: {
			includeSeriesDescription: useAppSelector(selectDetailsSynopsis),
			includeShowCards: useAppSelector(selectDetailsShowCard),
			includeCastAndCrewLinks: useAppSelector(selectCastBio)
		},
		pvrSettings: {
			enableWatch: useAppSelector(selectEnableWatch),
			enableRecord: useAppSelector(selectEnableRecord),
			enableBurnToDVD: useAppSelector(selectEnableBurnToDVD),
			enableRemoteScheduling: useAppSelector(selectEnableRemoteScheduling),
			channel: useAppSelector(selectPVRChannel)
		}
	}

	useEffect(() => {
		if (!channels.lineupCacheId) {
			dispatch(fetchLineupChannels({ userId: userId, lineupId: lineupId }));
		}

		if (params.channelIndex !== undefined) {
			setChannelIndex(channelIndex)
			setChannelIndex(params.channelIndex);
		} else {
			nav('pagenotfound', { replace: true })
		}
	})

	const getChannelName = (channel: Channel): string => {
		if (channel.majorChannel !== 0) {
			return `(${channel.majorChannel}.${channel.minorChannel}) ${channel.callSign}`
		} else {
			return `(${channel.rfChannel}) ${channel.callSign}`
		}
	}

	const pulldownChange = (cI: string) => {
		setChannelIndex(cI)
		const date = new Date();
		const timeStamp = date.getFullYear() + leadingZeroBuilder(date.getMonth() + 1) + leadingZeroBuilder(date.getDate()) + '0000';
		dispatch(fetchChannelLineup({ userId: userId, lineupId: lineupId, timeStamp, duration: '1440', channelIndex: cI }));
		nav(`../dailygrid/${cI}`)
	}

	return (
		<GridSelectorsContainer>
			<ChannelGridSelectorContainer>
				<BackButton onClick={() => setMainGridScrollID(-1)} to='/'>
					<GridSelectorImg src={Back} />
					<span>Back To List Grid</span>
				</BackButton>
				<GridPullDown value={channelIndex} onChange={(e) => pulldownChange(e.target.value)}>
					{channels.channels.map((c) => {
						return (
							(c.isNextGen && hideNextGen) ? '' :
								<option title={c.channelIndex + ''} key={CreateUUID()} value={c.channelIndex}>
									{`${getChannelName(c)} ${c.isNextGen ? ' (NG)' : ''}`}
								</option>)
					})}
				</GridPullDown>
				<GridRadioGroup>
					<GridRadioLabel>
						<GridRadio defaultChecked={days === '1'} value='1' name='numDays' type='radio' onChange={((e) => { handleChange(e) })} />
						1 Day
					</GridRadioLabel>
					<GridRadioLabel>
						<GridRadio defaultChecked={days === '3'} value='3' name='numDays' type='radio' onChange={handleChange} />
						3 Day
					</GridRadioLabel>
					<GridRadioLabel>
						<GridRadio defaultChecked={days === '5'} value='5' name='numDays' type='radio' onChange={handleChange} />
						5 Day
					</GridRadioLabel>
					<GridRadioLabel>
						<GridRadio defaultChecked={days === '7'} value='7' name='numDays' type='radio' onChange={handleChange} />
						7 Day
					</GridRadioLabel>
				</GridRadioGroup>
			</ChannelGridSelectorContainer>
			<ChannelGridIconContainer>
				{/* <GridSelectorImg src={Filter} /> */}
				<Printer title='Print Currently Displayed schedule' src={Print} onClick={() => window.print()} />
			</ChannelGridIconContainer>
		</GridSelectorsContainer>
	)
}

export default DailyGridSelector