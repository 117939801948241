import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { setModalContent, setModalOpen } from '../../../Modal/modalSlice';
import {
	ModalIconSM, ModalTitleBar, WhiteClose
} from '../../../Modal/ModalStyles';
import {
	StyledEventDetails, DetailsTitle, EventsDetailsContainer, ShowCard, DetailsEpisodeTitle, EventsDetailsContent,
	EventDetailsInfo, DetailsProgramInfo, GenreList, CreditsContainer, CreditLink, ViewAdditionalAirings, RemoteScheduleMessage
} from './EventDetailsStyles';
import Close from '../../../../img/Icons/White Icons/TitanTV Icon_Close_White.png';
import {
	selectEventDetailsStatus, selectEventDetails, selectDetailsChannel, fetchCastCrew, selectRemoteSchedulingStatus,
	setRemoteSchedulingStatus
} from '../../detailsSlice';
import { DataState, ModalState } from '../../../../interfaces/enums';
import GridLoading from '../GridLoading';
import { CreateUUID, fontSizeCalculation, leadingZeroBuilder } from '../../../Global/GobalFunctions';
import { EventDetails } from '../../../../interfaces/eventDetails';
import { EventDescription } from '../GridStyles';
import EventDetailsFlags from './EventDetailsFlags';
import EventControls from './EventControls';
import {
	selectCastBio, selectDetailsShowCard, selectDetailsSynopsis, selectFontSize, selectSeasonNumber, selectYearProduction,
	setDisplayBurn, setDisplayICal, setDisplayRecord, setDisplayWatch
} from '../../../UserSettingsPages/SettingsState/gridSettingsSlice';
import { selectGridLineupID } from '../../mainGridSlice';
import { selectUserId } from '../../../UserSettingsPages/SettingsState/userSlice';
import { fetchOtherAiringsJSON } from '../../searchAdditionAiringsSlice';
import GridFailed from '../GridFailed';
import { setIsFav, setIsReminder } from '../../../UserSettingsPages/SettingsState/favoritesRemidersSlice';
import { FreestarAd } from '../../../Freestar/FreestarAd';
import EventMediaLinks from './EventMediaLinks';
import GA4 from 'react-ga4'

const seasonInfo = (event: EventDetails, showSeason: boolean): string => {
	let seasonEpsString = '';
	if (event.seasonNumber === 0 || !showSeason || event.programType === 'Movie') {
		return seasonEpsString;
	}
	seasonEpsString = ' | Season ' + event.seasonNumber
	if (event.seasonEpisodeNumber <= 0) {
		return seasonEpsString
	}
	seasonEpsString = seasonEpsString + ' Episode ' + event.seasonEpisodeNumber
	return seasonEpsString
}

const genreList = (event: EventDetails) => {
	let genreListString = ''
	for (let i = 0; i < event.genres.length; i++) {
		genreListString = genreListString + event.genres[i].genreName;
		if ((i + 1 !== event.genres.length)) {
			genreListString = genreListString + ', '
		}
	}
	return genreListString;
}

const newRepeat = (str: string): string => {
	if (str === 'N') { return 'New' }
	if (str === 'R') { return 'Repeat' }
	return ''
}

const startTimeString = (startTime: Date): string => {
	let returnStr = '';
	if (startTime.getFullYear() < 1928) { return returnStr; }
	let AMPM = 'AM'
	let hours = startTime.getHours();
	let mins = leadingZeroBuilder(startTime.getMinutes());
	if (hours >= 12) { AMPM = 'PM'; }
	if (hours > 12) { hours = hours - 12; }
	if (hours === 0) { hours = 12; }
	returnStr = startTime.getMonth() + 1 + '/' + startTime.getDate() + ' ' + hours + ':' + mins + ' ' + AMPM + ', ';
	return returnStr;
}

const handleCastClick = (channelId: number, programId: number, personId: number, dispatch: any) => {
	dispatch(fetchCastCrew({ channelId, programId, personId }));
	dispatch(setModalContent(ModalState.CASTANDCREW))
}

const handleOtherAirings = (data: { userId: string, lineupId: string, title: string, numberOfDays: number }, dispatch: any) => {
	dispatch(fetchOtherAiringsJSON(data));
	document.body.style.overflow = 'visible';
	dispatch(setModalOpen())
}

const EventDetailsBox = () => {
	const dispatch = useAppDispatch();
	const event = useAppSelector(selectEventDetails);
	const channel = useAppSelector(selectDetailsChannel);
	const displaySynopsis = useAppSelector(selectDetailsSynopsis);
	const displayShowCard = useAppSelector(selectDetailsShowCard);
	const displayCastBio = useAppSelector(selectCastBio);
	const fontSizeModifer = useAppSelector(selectFontSize);
	const fontSize = fontSizeCalculation(parseFloat(fontSizeModifer));
	const showSeasonNumber = useAppSelector(selectSeasonNumber);
	const showMovieYear = useAppSelector(selectYearProduction);
	const userId = useAppSelector(selectUserId);
	const lineupId = useAppSelector(selectGridLineupID);
	const remoteScheduleStatus = useAppSelector(selectRemoteSchedulingStatus);

	const startDateTime = new Date(event.startTime);
	const duration = event.duration / 60;
	let durationHours = ((duration / 60) | 0);
	let durationMins = duration % 60
	const loading = useAppSelector(selectEventDetailsStatus);
	const airDate = new Date(event.originalAirDate);

	const setAirDateString = () => {
		if (event.programType === 'Movie' && showMovieYear) {
			return event.year
		} else if (event.originalAirDate === '0001-01-01T00:00:00') {
			return ''
		} else {
			return (airDate.getMonth() + 1) + '/' + airDate.getDate() + '/' + airDate.getFullYear()
		}
	}

	const handleClose = () => {
		document.body.style.overflow = 'visible';
		dispatch(setIsFav(false));
		dispatch(setIsReminder(false));
		dispatch(setModalContent(ModalState.WELCOME));

		dispatch(setDisplayWatch(true));
		dispatch(setDisplayRecord(true));
		dispatch(setDisplayBurn(true));
		dispatch(setDisplayICal(true));

		dispatch(setModalOpen());
	}

	useEffect(() => {
		//GA4.send({ hitType: "pageview", page: '/details' });
		dispatch(setIsFav(false));
		document.body.style.overflow = 'hidden';

		return () => {
			//document.body.style.overflow = 'visible';
			if (remoteScheduleStatus !== DataState.IDLE) {
				dispatch(setRemoteSchedulingStatus(DataState.IDLE))
			}
			dispatch(setIsReminder(false));
			dispatch(setIsFav(false));
		}
		// eslint-disable-next-line
	}, []);

	return (
		<StyledEventDetails id='details' onClick={(e) => e.stopPropagation()}>
			<ModalTitleBar>
				<EventControls event={event} channelIndex={channel.channelIndex} channel={channel} />
				{remoteScheduleStatus === DataState.SUCCEEDED ? <RemoteScheduleMessage>Event Scheduled</RemoteScheduleMessage> : ''}
				<WhiteClose onClick={() => handleClose()}>
					Close <ModalIconSM src={Close} alt="" />
				</WhiteClose>
			</ModalTitleBar>
			<EventsDetailsContainer>
				{(loading === DataState.FAILED) ? <GridFailed errorCode={event.errorCode} errorMessage={event.errorMessage} /> :
					(loading === DataState.LOADING) ? <GridLoading /> :
						<>
							{displayShowCard ? <ShowCard src={event.showCard} onError={(event) => event.currentTarget.style.display = 'none'} /> : ''}
							<EventsDetailsContent fontSize={parseFloat(fontSizeModifer)}>
								<DetailsTitle>
									{event.title}
								</DetailsTitle>
								<EventDetailsInfo>
									{channel.callSign} - {channel.majorChannel > 0 ? channel.majorChannel + '.' + channel.minorChannel : channel.rfChannel},&nbsp;
									{startTimeString(startDateTime)}
									{durationHours !== 0 ? durationHours + ' HR' : ''}{durationMins !== 0 ? ' ' + durationMins + ' MINS' : ''}
								</EventDetailsInfo>
								{event.episodeTitle ? <DetailsEpisodeTitle>{'"' + event.episodeTitle + '"'}</DetailsEpisodeTitle> : ''}
								<DetailsProgramInfo>
									{newRepeat(event.newRepeat)} {' ' + setAirDateString() + seasonInfo(event, showSeasonNumber)}
								</DetailsProgramInfo>
								<GenreList>{genreList(event)}</GenreList>
								{displaySynopsis ?
									<EventDescription fontSize={fontSize + 'px'}>{event.description}</EventDescription> : ''
								}
								<br />
								{displayCastBio && (event.castAndCrew.length > 0) ?
									<CreditsContainer>
										Credits: {event.castAndCrew.map((cast, i, row) =>
											<div key={`cast${CreateUUID()}`}>
												<CreditLink key={`CastCrew${CreateUUID()}`} onClick={() => handleCastClick(channel.channelId, event.programId, cast.personId, dispatch)}>
													{cast.name} ({cast.role})
												</CreditLink>
												<br key={cast.personId + ' break'} />
											</div>
										)}
									</CreditsContainer> : ''
								}
								<EventDetailsFlags Show={event} />
								<EventMediaLinks />
								<ViewAdditionalAirings to='/OtherAirings' onClick={() => handleOtherAirings({ userId: userId, lineupId: lineupId, title: event.title, numberOfDays: 14 }, dispatch)}>
									View Additional Airings
								</ViewAdditionalAirings>
							</EventsDetailsContent>
						</>}
				{/* <DetailsMockAd /> */}
				<FreestarAd placementName={'titantv_show_description'}></FreestarAd>
			</EventsDetailsContainer>
		</StyledEventDetails>
	)
}

export default EventDetailsBox